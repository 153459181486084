<template>
    <mnSup mostrar="true"/>
    <main id="eval-sala" v-if="loged">
        <section class="botonesIngreso">
            <a href="/pauta-evaluaciones" v-if="session.profile.nombre == 'Evaluador'">
                <!-- <img src="/assets/img/salaEval-btn2.svg" alt=""> -->
                PAUTA DE<br> EVALUACIÓN
            </a>
        </section>

        <section class="footer">
            <div class="wrap">
                <img src="/assets/img/salaEval-footer.svg" alt="">
            </div>
        </section>
        
    </main>
    <footerLogo />
</template>

<script>
import mnSup from '@/components/mnSup.vue'
import footerLogo from '@/components/footerLogo.vue'
import {mapState, mapMutations} from 'vuex';

export default {
    name: 'Evaluaciones',
    components: {
        mnSup, footerLogo
    },
    async created(){
        document.title = 'SALA DE EVALUACIONES - XXI Congreso Nacional Explora'
        await this.logVerify()
    },
    methods:{
        ...mapMutations(['logVerify'])
    },
    computed:{
        ...mapState(['apiURL', 'loged', 'session']),
    }
}
</script>

<style lang="scss" scoped>
main#eval-sala{
    padding:20px;
    min-height: calc(100vh - 80px);
    section.botonesIngreso{
        display: flex;
        justify-content: flex-end;
        margin-bottom:20px;
        @media(max-width:1100px){
            justify-content: center;
        }
        img{
            width: 300px;
        }
        a{
            text-align: center;
            padding:17px 30px;
            width: fit-content;
            background-color: var(--color2);
            color:#fff;
            position: relative;
            border-radius: 100px;
            font-weight: 900;
            text-decoration: none;
            font-size: 1.2rem;
            min-width: 300px;
            display: inline-block;
            &::after{
                content: '';
                position: absolute;
                top:6px;
                left:0px;
                z-index: 0;
                width: 99%;
                height: 99%;
                border: 3px solid #000;
                border-radius: 100px;
            }
            &:hover{
                background-color: #000;
            }
        }
    }
    section.salasIngreso{
        margin-bottom:50px;
        .wrap{
            display: flex;
            justify-content: center;
            gap:30px;
            flex-wrap: wrap;
            max-width: 1250px;
            a{
                text-align: center;
                font-size: 1.2rem;
                background-color: transparent;
                padding:15px;
                text-decoration: none;
                color: var(--color2);
                border-radius: 17px;
                p{
                    margin-bottom: 16px;
                    font-weight: 700;
                }
                img{
                    width: 250px;
                    height: auto;
                }
                &:hover{
                    background-color: #000;
                    color:#fff;
                }
            }
            
        }
        
    }
    section.footer{
        margin-bottom:90px;
    }
    
}
</style>